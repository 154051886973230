<template>
  <!-- 登录组件 -->
  <div>
    <div v-if="showWrap">
      <div
        class="login-btn"
        @click="showLogin = true"
        v-if="!userState"
      >
        {{ loginInfo.login }}
        
      </div>
      <el-dropdown @command="logoutBtn" trigger="click" v-else>
        <div class="login-btn">
          <span class="pageStatus">
            {{ showName }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dashborad"  style="text-align:center"
            >{{ $t("dashborad") }}</el-dropdown-item
          >
          <el-dropdown-item command="account"  style="text-align:center"
            >{{ $t("account") }}</el-dropdown-item
          >
          <el-dropdown-item command="exit" style="text-align:center"
            >{{ $t("exit") }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="long-container" v-if="showLogin">
      <div class="login">
        <img
          class="close-icon"
          :src="$getImages(loginInfo.closeIcon)"
          alt=""
          @click="closeWrap(false)"
        />
        <div class="title">{{ loginInfo.appTitle }}</div>
        <div class="des">{{ des }}</div>

        <div class="re-input">
          <div class="input">
            <input
              ref="inputs"
              v-model="emailValue"
              type="text"
              :placeholder="loginInfo.emaliPlaceholder"
              @focus="setFocus('num')"
            />
            <img v-show="isEmailInvalid" class="icon" src="" alt="" />
          </div>
          <div class="error-tip" v-html="emailError"></div>
        </div>
        <div class="re-input">
          <div class="input">
            <input
              v-model="passwordValue"
              type="password"
              :placeholder="loginInfo.passwordPlaceholder"
              @focus="setFocus('pass')"
            />
            <img v-show="isPasswordInvalid" class="icon" src="" alt="" />
          </div>
          <div class="error-tip" v-html="passwordError"></div>
        </div>
        <el-button
          class="commit"
          type="primary"
          :loading="loadStart"
          @click="!loadStart && handleLogin()"
        >
          {{ loginInfo.loginButton }}
        </el-button>

        <!-- 忘记密码 -->
        <div class="tips-wrap">
          <br>
          <span @click="goPath($origin('/r/register'))">{{ $t("signIn") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "LoginWrap",
  props: {
    showWrap: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadStart: false,
      emailValue: "",
      passwordValue: "",
      isEmailInvalid: false,
      isPasswordInvalid: false,
      des: "",
      tipsType: "",
      emailError: "",
      passwordError: "",
      showLogin: false,
      userState: false,
      btnType: "",
    };
  },
  watch: {
    loadStart() {
      setTimeout(() => {
        this.loadStart = false;
      }, 2000);
    },
    trigger(value) {
      if (value) {
        this.showLogin = value;
        this.OPEN_LOGIN(false);
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "loginInfo"]),
    trigger() {
      return this.$store.state.triggerOpen;
    },
    showName(){
      const $id = this.$route.params.id;
      if($id ==='account'){
      return this.$t("account")
      }
      if($id ==='upload'){
      return this.$t("dashborad")
      }
      return this.$t("home")
    },
  },
  mounted() {
    this.cheackLogin();
    // 监听键盘事件
    this.$el.addEventListener("keydown", this.handleKeyDown);
    this.$nextTick(() => {
      this.des = this.loginInfo.loginDes;
    });
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听
    this.$el.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    ...mapMutations(["OPEN_LOGIN"]),
    open() {
      this.showLogin = true;
    },
    goPath(path){
      this.OPEN_LOGIN(false)
      this.showLogin = false
      // this.$router.push(path)
      window.location.href = path
    },
    cheackLogin() {
      const user_token_value = localStorage.getItem("user_token_value");
      if(!user_token_value){
        return;
      }
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        const userInfoJson = JSON.parse(userInfo);
        if (userInfoJson.email) {
          this.userState = userInfoJson.email;
          this.btnType = this.userState;
        }
        return;
      }
      this.$api.GetUserInfo().then((result) => {
        if (result.code === 200) {
          this.userState = result.data.email;
          this.btnType = this.userState;
          localStorage.setItem("userInfo", JSON.stringify(result.data));
        } else {
        localStorage.removeItem('user_token_key');
        localStorage.removeItem('user_token_value');
        localStorage.removeItem('user_email');
        localStorage.removeItem('userInfo');
        }
      });
    },
    logoutBtn(type) {
      if (type === "dashborad") {
        const oldUrl = window.location.href;
        const newUrl = window.location.origin + "/s/upload";
        if (newUrl !== oldUrl) window.open(newUrl, "_self");
      } else if (type === "account") {
        const oldUrl = window.location.href;
        const newUrl = window.location.origin + "/s/account";
        if (newUrl !== oldUrl) window.open(newUrl, "_self");
      }  else {
        this.$api
          .Logout()
          .then((result) => {
            this.$store.commit("REMOVE_USER_INFO");
            this.cheackLogin();
            if (this.$route.path !== "/") this.$router.push({ path: "/" });
          })
          .catch((err) => {
            // console.log(err, '失败 >>>')
          });
      }
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        this.handleLogin();
      }
    },
    // 登录
    handleLogin() {
      this.loadStart = true;
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!this.emailValue) {
        this.emailError = this.loginInfo.emailErrorNull;
        return;
      }
      if (!reg.test(this.emailValue)) {
        this.emailError = this.loginInfo.emailErrorText;
        return;
      }
      if (!this.passwordValue) {
        this.passwordError = this.loginInfo.passwordErrorNull;
        return;
      }
      if (this.passwordValue.length < 6 || this.passwordValue.length > 30) {
        this.passwordError = this.loginInfo.passwordErrorText;
        return;
      }
      let apiName = this.$api.Login;
      apiName({
        identityType: "EMAIL",
        identifier: this.emailValue,
        credential: this.passwordValue,
      })
        .then((result) => {
          // console.log(result, '---->> 登录返回');
          if (result.code == 200) {
            let res = result.data || {};
            this.$store.commit("SET_USER_INFO", res);
            window.location.href = this.$origin('/s/upload')
            this.closeWrap(false);
          } else {
            this.$notify({
              title: "error",
              message: result.msg,
              type: "error",
            });
          }
        })
        .catch((e) => {
          // console.log(e, '---->> 失败回调');
        });
    },
    // 切换
    // switchingMethod(type) {
    //   console.log(type, '切换----->>')
    //   if (type !== 'Log in') {
    //     this.des = this.loginInfo.createDes
    //     this.tipsType = this.loginInfo.login
    //   } else {
    //     this.des = this.loginInfo.loginDes
    //     this.tipsType = this.loginInfo.createtips
    //   }
    // },
    // 清空
    setFocus(type) {
      type == "num" ? (this.emailError = "") : (this.passwordError = "");
    },
    closeWrap(type) {
      this.showLogin = type;
      this.emailValue = "";
      this.passwordValue = "";
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .login-btn {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 16px;
    color: var(--primary-box-btn-color);
      background: var(--primary-box-btn);
    
      .pageStatus {
        display: inline-block;
        width: 100px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    .long-container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 998;
      background: rgba(0, 0, 0, 0.3);
    
      .login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 60px 40px 80px;
        background: var(--white);
          border-radius: 8px;
          box-sizing: border-box;
          min-width: 400px;
        
          .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 15px;
            cursor: pointer;
          }
        
          .title {
            margin-bottom: 12px;
            font-size: 32px;
            font-weight: 600;
            font-style: normal;
            font-family: Poppins;
            color: var(--primary-box-black);
            line-height: normal;
          }
        
          .des {
            margin-bottom: 24px;
            font-size: 22px;
            font-weight: 600;
            font-style: normal;
            font-family: Poppins;
            color: var(--primary-box-black);
          }
        
          .re-input {
            width: 100%;
        
            .input {
              position: relative;
              height: 48px;
              box-sizing: content-box;
        
              input {
                width: 100%;
                height: 97%;
                margin-bottom: 1px;
                padding-right: 32px;
                font-size: 16px;
                box-sizing: border-box;
                border: none;
                outline: none;
                border-bottom: 1px solid #dadce0;
        
                &:focus {
                  border-bottom: 1px solid #000029;
                }
        
                &:hover {
                  border-bottom: 1px solid #babec5;
                }
              }
        
              .icon {
                position: absolute;
                right: 0;
                width: 16px;
                padding: 6px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
        
                &:hover {
                  background: #f5f5f5;
                }
              }
            }
        
            .err-input {
              input {
                border-bottom: 1px solid #f14242;
              }
            }
        
            .error-tip {
              margin-top: 4px;
              font-size: 14px;
              font-family: Poppins;
              color: var(--theme-focus-err);
              transition: all 0.3s;
            }
          }
        
          .commit {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 48px;
            margin-top: 36px;
            font-size: 18px;
            font-weight: 500;
            background: var(--primary-box-btn);
            color: #fff;
            border-radius: 25px;
            border: none;
            outline: none;
            cursor: pointer;
            border: none;
          }
        
          .tips-wrap {
            margin: 16px 0;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            font-family: Poppins-Regular, Poppins;
            color: var(--primary-box-black);
            line-height: normal;
        
            span {
              color: #416beb;
              cursor: pointer;
        
              &:hover {
                color: #1e4acf;
              }
        
              &:active {
                color: #3258ce;
              }
            }
          }
        
          .toast {
            position: absolute;
            top: 36px;
            left: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 280px;
            height: 52px;
            border-radius: 4px;
            box-shadow: 0px 2px 8px 0px rgba(53, 58, 74, 0.15);
            background: var(--white);
            animation: showing 0.3s linear;
        
            .success {
              margin-left: 8px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              color: #4fcb81;
              line-height: normal;
            }
        
            .isErr {
              margin-left: 8px;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              font-family: Poppins;
              color: var(--theme-focus-err);
              line-height: normal;
            }
          }
        }
        }
        }
        
        @media (max-width: 980px) {
          .login-btn {
            display: flex;
            align-items: center;
            color: var(--theme-suffix-box);
            // background: var(--primary-box-btn);
            line-height: 15px !important;
            padding: 0 0.2rem;
        
            .pageStatus {
              display: inline-block;
              font-size: 16px;
              // width: 60px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        
          .long-container {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 998;
            background: rgba(0, 0, 0, 0.3);
        
            .login {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding: 0.4rem 0.5rem 1rem;
              background: var(--white);
              border-radius: 0.2rem;
              box-sizing: border-box;
              width: 90%;
        
              .close-icon {
                position: absolute;
                top: 0.25rem;
                right: 0.25rem;
                width: 0.35rem;
                cursor: pointer;
              }
        
              .title {
                margin: 0.25rem 0 0.2rem;
                font-family: Poppins;
                font-size: 0.4rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: var(--primary-box-black);
              }
        
              .des {
                margin-bottom: 0.6rem;
                font-size: 0.3rem;
                font-weight: 600;
                font-style: normal;
                font-family: Poppins;
                color: var(--primary-box-black);
                line-height: none !important;
              }
        
              .re-input {
                .input {
                  position: relative;
                  height: 0.8rem;
                  box-sizing: content-box;
        
                  input {
                    width: 100%;
                    height: 0.5rem;
                    padding-bottom: 0.1rem;
                    padding-right: 0.2rem;
                    box-sizing: border-box;
                    border: none;
                    outline: none;
                    border-bottom: 1px solid #dadce0;
                    font-size: 0.28rem;
        
                    &:focus {
                      border-bottom: 1px solid #000029;
                    }
        
                    .icon {
                      position: absolute;
                      right: 0;
                      width: 1rem;
                      padding: 0.1rem;
                      top: 50%;
                      transform: translateY(-50%);
                      cursor: pointer;
        
                      &:hover {
                        background: #f5f5f5;
                      }
                    }
                  }
                }
        
                .err-input {
                  input {
                    border-bottom: 1px solid #f14242;
                  }
                }
        
                .error-tip {
                  margin: 0.2rem 0;
                  font-size: 0.26rem;
                  font-family: Poppins;
                  color: var(--theme-focus-err);
                  transition: all 0.3s;
                }
              }
        
              .commit {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 0.9rem;
                margin: 0 auto;
                margin-top: 0.6rem;
                border-radius: 0.6rem;
                font-size: 0.35rem;
                font-weight: 500;
                background: var(--primary-box);
                color: var(--white);
        line-height: 1rem;
        border: none;
        cursor: pointer;
      }

      .tips-wrap {
        margin: 0.2rem 0;
        font-size: 0.26rem;
        font-weight: 400;
        font-style: normal;
        font-family: Poppins-Regular, Poppins;
        color: var(--primary-box-black);
        line-height: normal;

        span {
          color: #416beb;
          cursor: pointer;

          &:hover {
            color: #1e4acf;
          }

          &:active {
            color: #3258ce;
          }
        }
      }

      .toast {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0.3rem;
        left: 0.6rem;
        width: 5rem;
        height: 0.8rem;
        font-size: 0.3rem;
        font-weight: 500;
        font-style: normal;
        font-family: Poppins;
        box-shadow: 0px 0.02rem 0.4rem 0px rgba(53, 58, 74, 0.15);
        background: var(--white);
        border-radius: 0.2rem;
        animation: showing 0.3s linear;

        .success {
          color: #4fcb81;
          margin-left: 0.2rem;
        }

        .isErr {
          margin-left: 0.2rem;
          color: var(--theme-focus-err);
        }
      }
    }
  }
}
</style>

<template>
  <div class="koutu_warp">
    <div class="koutu_step">
      <p class="title">{{ content.title }}</p>
      <div class="area">
        <div v-for="(item, index) in content.list" :key="index" class="item">
          <div class="stepText" v-if="item.stepText">{{ item.stepText }}</div>
          <p class="subtitle">{{ item.subtitle }}</p>
          <p class="explain">{{ item.text }}</p>
        </div>
      </div>
    </div>
    <img :src="$getImages(content.bg)" class="koutu_bg" v-if="content.bg" alt="" />
  </div>
</template>
  
  <script>
export default {
  name: "Koutu_Step_QuickChop",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
  
  <style scoped lang="scss">
  .koutu_warp {
    position: relative;

    .koutu_bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  @media #{'only screen and (min-width: 992px)'} {
    .koutu_step {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 1080px;
      margin: 0 auto;
      padding: 100px 0;
      font-family: Arial, Arial;

      .title {
        margin-bottom: 12px;
        font-size: 60px;
        font-weight: 900;
        color: var(--white);
        line-height: 1.3;
        text-align: center;
        width: 70%;
        margin: 0 auto 40px;
      }

      .area {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        width: 100%;
        margin-top: 42px;

        .item {
          position: relative;
          padding: 30px;
          background: #f6f6f6;
          box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
          border-radius: 2px;
          overflow: hidden;
          transition: all 0.3s;
          overflow: visible;

          &:hover {
            transform: translateY(-5px);
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            // background: white;
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);

            .subtitle,
            .explain {
              color: var(--primary-box-black);
            }

            .stepText {
              color: var(--primary-box-black);
            }

            // background: var(--theme-suffix-box);
          }

          .subtitle {
            margin-bottom: 10px;
            font-size: 25px;
            font-weight: bold;
            color: var(--primary-box-black);
            line-height: 1.2;
          }

          .explain {
            margin-top: 20px;
            font-size: 20px;
            color: var(--primary-box-black);
            line-height: 1.2;
          }

          .stepText {
            padding: 10px 20px;
            color: #666066;
            display: inline-block;
            font-size: 20px;
            margin-bottom: 30px;
            text-align: center;
            border-radius: 2px;
            background-color: #e6e6e6;
          }
        }
      }
    }
  }

  @media #{'only screen and (max-width: 991px)'} {
    .koutu_step {
      width: 100%;
      padding: 0.8rem 0;
      font-family: Arial, Arial;

      .title {
        margin: 0 auto 1rem;
        font-size: 0.48rem;
        color: var(--white);
        text-align: center;
        font-weight: 900;
      }

      .area {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        margin-top: 1rem;

        .item {
          position: relative;
          padding: 0 0.4rem 0.8rem;
          background: #f6f6f6;
          border-radius: 0.2rem;
          //   transition: all .3s;
          overflow: visible;
          margin: 0 0.3rem;

          .subtitle {
            margin-bottom: 0.3rem;
            font-size: 0.5rem;
            font-weight: bold;
            color: var(--primary-box-black);
          }

          .explain {
            // margin-top: 20px;
            font-size: 0.3rem;
            color: var(--primary-box-black);
            // line-height: 1.2;
          }

          .stepText {
            padding: 0.2rem 0.5rem;
            color: #666066;
            display: inline-block;
            font-size: 0.35rem;
            margin-bottom: 0.5rem;
            text-align: center;
            background-color: #e6e6e6;
          }
        }
      }
    }
  }
</style>
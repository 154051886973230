<template>
  <div class="pricing-wrapper">
    <div class="pricing-section" id="pricing">
      <h1 class="pricing-heading">{{ content.title }}</h1>
      <p class="pricing-description">{{ content.des }}</p>
      <div class="pricing-plans">
        <div class="pricing-plan" v-for="(item, idx) in content.priceSetup" :key="idx">
          <div class="plan-header">
            <div class="plan-tag">{{ item.tag }}</div>
            <div class="plan-price" v-html="item.price
              .replace(/{money}/g, productList[idx].money)
              .replace(/{useTime}/g, productList[idx].useTime)
              .replace(/{useTime1}/g, productList[idx].useTime1)
              "></div>
          </div>
          <ul>
            <li class="plan-feature" v-for="line in item.desList" :key="line">
              {{ line }}
            </li>
          </ul>
          <button :style="`cursor: ${loading ? 'wait' : ''}`" v-show="productList.length" class="plan-cta"
            @click="handlePay(idx)">
            {{ item.buttonTxt }}
          </button>
          <p class="plan-note" v-if="item.tip" v-html="item.tip
            .replace(/{money}/g, productList[idx].money)
            .replace(/{price}/g, productList[idx].price)
            .replace(/{useTime1}/g, productList[idx].useTime1)
            .replace(/{useTime}/g, productList[idx].useTime)
            .replace(/{useTime2}/g, productList[idx].useTime2)
            "></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const currencyMap = {
  USD: "$",
  PHP: "₱",
  ZAR: "R",
  GBP: "£",
  SGD: "S$",
  NZD: "NZ$",
  EUR: "€",
  INR: "₹",
  CAD: "C$",
  AUD: "AUD",
  IDR: "Rp",
  MYR: "RM",
  KRW: "₩",
  HKD: "HK$",
  TWD: "NT$",
  BRL: "R$",
  THB: "฿",
};

const offerTypePrices = {
  0: "price",
  1: "tryoutPrice",
  2: "firstDiscountPrice",
};

const durationUnitType = {
  1: "hour",
  2: "day",
  3: "week",
  4: "month",
  5: "year",
  100: "Lifetime",
};

//  填充价格套餐
const useFillPriceInfo = (item) => {
  item.money = currencyMap[item.currency] + item[offerTypePrices[item.offerType]];
  item.price = currencyMap[item.currency] + item.price;

  let unit = "durationUnit";
  let duration = "duration";
  if (item.offerType === 1) {
    unit = "tryoutDurationUnit";
    duration = "tryoutDuration";
  }
  const durationUnit = item[unit];
  const durationDate = item[duration];

  item.useTime = durationUnitType[durationUnit];
  item.useTime1 = durationUnitType[durationUnit];
  item.useTime2 = durationUnitType[item.durationUnit];
  if (durationDate === 100) {
    item.useTime = "LifeTime";
  } else {
    item.useTime = durationDate + "-" + item.useTime;
  }

  item.currencyUnit = currencyMap[item.currency];

  return item;
};

import { getProductList, createOrder, payOrder } from "@/api/pay";
import { $extractDomain } from "@/utils/index";
export default {
  name: "Koutu_Price",
  props: {
    payUrl: {
      type: Object,
      required: true,
      default: () => { },
    },
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {
      loading: false,
      productList: [
        {
          money: "19.99",
          price: "39.99",
          useTime: "1-Month",
        },
        {
          money: "99.99",
          price: "299.99",
          useTime: "1-Year",
        },
      ],
    };
  },
  created() {
    let mainPart = $extractDomain(window.location.hostname);
    let lang = this.$route.params.lang || "en";
    if (lang !== "en") {
      mainPart = `${mainPart}_${lang}`;
    }
    getProductList({ code: mainPart, channel: this.$route.query.channel, extend: {} }).then(result => {
      if (result.data && result.data.length) {
        this.productList = result.data.map(useFillPriceInfo);
      }
    });
  },
  mounted() {
    if (this.$route.hash) {
      this.$nextTick(() => {
        document.querySelector(this.$route.hash) &&
          document.querySelector(this.$route.hash).scrollIntoView();
      });
    }
  },
  methods: {
    async handlePay(idx) {
      if (!this.productList[idx].productsPriceList) {
        return this.nopay();
      }
      if (this.loading) return;
      this.loading = true;
      try {
        this.$emit('goPay', item.id)
        this.loading = false;
      } catch (e) {
        this.loading = false;
        alert(e.msg || e);
      }
    },
    nopay() {
      alert("Please wait patiently for us to access the payment");
    },
  },
};
</script>

<style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
  .pricing-wrapper {
    background: #f6f6f6;

    .pricing-section {
      width: 1080px;
      margin: 0 auto;
      padding: 50px 0 100px;
      text-align: center;
      font-family: Arial, Arial;
      padding-top: 120px;

      .pricing-heading {
        margin-bottom: 24px;
        font-size: 60px;
        font-weight: 900;
        color: var(--primary-box-black);
        text-align: center;
      }

      .pricing-description {
        font-size: 16px;
        font-weight: 400;
        color: var(--primary-box-black);
        line-height: 24px;
        margin-bottom: 44px;
      }

      .pricing-plans {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        text-align: left;

        .pricing-plan {
          margin: 0 auto;
          background: var(--theme-suffix-box);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          overflow: hidden;

          .plan-header {
            background: var(--primary-box-dark-sub);

            .plan-tag {
              padding: 40px 0 0;
              font-size: 30px;
              text-align: center;
              color: white;
            }

            .plan-price {
              padding: 20px 0;
              font-weight: 900;
              font-size: 40px;
              color: white;
              text-align: center;
            }
          }

          ul {
            margin: 50px 0 24px;
            min-height: 250px;
            padding: 0 50px;
            list-style: none;
            text-align: center;

            li {
              font-size: 20px;
              color: white;
              margin-bottom: 15px;

              &::marker {
                color: #d9d9d9;
              }
            }
          }

          .plan-cta {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px 0;
            width: 80%;
            font-size: 26px;
            border: none;
            font-weight: bold;
            background: var(--theme-koutu-price-btn);
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            color: var(--primary-box-black);
            margin: 0 auto 40px;
            line-height: 24px;
            cursor: pointer;
          }

          .plan-note {
            margin-top: 10px;
            font-size: 16px;
            color: white;
            padding: 25px 32px;
            background: var(--primary-box-light-sub);
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .pricing-wrapper {
    .pricing-section {
      width: 100%;
      margin: 0.5rem auto;
      text-align: center;
      padding: 0.32rem;
      box-sizing: border-box;
      font-family: Arial, Arial;

      .pricing-heading {
        margin-bottom: 24px;
        font-size: 36px;
        color: var(--primary-box-black);
        line-height: 42px;
        text-align: center;
      }

      .pricing-description {
        margin-bottom: 44px;
        font-size: 16px;
        font-weight: 400;
        color: var(--primary-box-black);
        line-height: 24px;
      }

      .pricing-plans {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        text-align: left;

        .pricing-plan {
          min-width: 100%;
          margin: 0 auto;
          background: var(--theme-suffix-box);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
          border-radius: 0.2rem;
          overflow: hidden;

          .plan-header {
            background: var(--primary-box-dark-sub);

            .plan-tag {
              padding: 0.5rem 0 0;
              font-size: 0.4rem;
              text-align: center;
              color: white;
            }

            .plan-price {
              padding: 0.5rem 0;
              font-weight: 900;
              font-size: 0.6rem;
              color: white;
              text-align: center;
            }
          }

          ul {
            margin: 0.5rem 0;
            padding: 0 0.6rem;
            list-style: none;
            text-align: center;

            li {
              font-size: 0.3rem;
              margin-bottom: 0.2rem;
              color: white;

              &::marker {
                color: #d9d9d9;
              }
            }
          }

          .plan-cta {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.4rem;
            width: 70%;
            margin-bottom: 1rem;
            color: var(--primary-box-black);
            padding: 0.3rem 0;
            font-weight: bold;
            background: var(--theme-koutu-price-btn);
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
            margin: 1rem auto 0.5rem;
            border-radius: 0.15rem;
            border: none;
            outline: none !important;
          }

          .plan-note {
            font-size: 0.3rem;
            color: white;
            padding: 0.5rem;
            background: var(--primary-box-light-sub);
          }
        }
      }
    }
  }
}
</style>
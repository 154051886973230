<template>
  <!-- SpeakToText组件 -->
  <div class="speak-to-text-warp">
    <div class="title">{{ i18nContent.title }}</div>
    <div class="speak-box">
      <div class="speak-left">
        <div class="speak-left-title">{{ i18nContent.subTitle }}</div>
        <div class="speak-list">
          <div
            class="speak-item"
            :class="[{ active: useAudioIdx === item.toneType }]"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="speak-item-top">
              <img
                src="@/assets/images/speaktotext/women.png"
                v-if="item.sex === 'FEMALE'"
                alt=""
              />
              <img
                src="@/assets/images/speaktotext/man.png"
                v-else-if="item.sex === 'MALE'"
                alt=""
              />
              <div class="speak-item-top-left">
                <div class="name">{{ item.name }}</div>
                <el-tag size="mini" class="tag" type="info">
                  ({{ item.sex }} voice)
                </el-tag>
              </div>
            </div>
            <div class="speak-item-button-list">
              <m-audio
                :block="false"
                text="Audition"
                :src="item.audition"
              ></m-audio>
              <div class="use-button" @click="useAudio(item)">Use</div>
            </div>
          </div>
        </div>
      </div>
      <div class="speak-right" v-loading="loading">
        <div class="input">
          <el-input
            type="textarea"
            class="textarea"
            :rows="isMobile ? 10 : 22"
            :placeholder="i18nContent.placeholder"
            v-model="textarea"
            :maxlength="500"
          >
          </el-input>
          <div class="speak-active-box" v-if="activeSrc">
            <m-audio
              :block="false"
              text="Audition"
              :showDuration="true"
              :src="activeSrc"
            ></m-audio>
            <div class="use-button" @click="downloadMP3(activeSrc)">Export</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MAudio from "./ui/audio.vue";
import { conversionList, conversion } from "@/api/pay";

export default {
  name: "SpeakToText",
  components: { MAudio },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      expirationTime: false,
      textarea: "",
      activeSrc: "",
      useAudioIdx: 1,
      list: [],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768; // 根据实际需求设置屏幕宽度阈值
    },
    i18nContent() {
      let i18nValue = {
        errorTips: "Please enter text",
        errorTips1: "Please buy the package first.",
        title: "Speak Your Text",
        subTitle: "Choose your favorite sound",
        placeholder: "A maximum of 500 characters can be converted each time",
      };
      if (this.lang === "id") {
        i18nValue = {
          errorTips: "Silakan masukkan teks",
          errorTips1: "Harap beli paket terlebih dahulu.",
          title: "Ucapkan Teks Anda",
          subTitle: "Pilih suara favorit Anda",
          placeholder: "Maksimal 500 karakter dapat dikonversi setiap kali",
        };
      }
      return i18nValue;
    },
    lang() {
      return this.$route.params.lang || "en";
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      conversionList().then((result) => {
        this.list = result.data.map((res) => {
          return res;
        });
        this.useAudioIdx = this.list[0].toneType;
      });
      const user_token_value = localStorage.getItem("user_token_value");
      if (!user_token_value) {
        return;
      }
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        const userInfoJson = JSON.parse(userInfo);
        if (userInfoJson.email) {
          this.expirationTime = true;
        }
        return;
      }
      this.$api.GetUserInfo().then((result) => {
        if (result.code === 200) {
          this.expirationTime = true;
        }
      });
    },
    async playAudio() {
      try {
        this.loading = true;
        const response = await conversion({
          text: this.textarea,
          toneType: this.useAudioIdx,
        });
        // Convert array buffer to blob
        const blob = new Blob([response], { type: "audio/mpeg" });
        // Create URL for the blob
        const audioUrl = URL.createObjectURL(blob);
        // Set the URL as the source for the audio element
        this.activeSrc = audioUrl;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching and playing audio:", error);
      }
    },
    downloadMP3(url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = "audio.mp3";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    useAudio(item) {
      if (!this.textarea) {
        this.$message({
          message: this.i18nContent.errorTips,
          type: "error",
        });
        return;
      }
      if (!this.expirationTime) {
        this.$message({
          message: this.i18nContent.errorTips1,
          type: "error",
        });
        window.location.href = this.$origin("/s/price");
        return;
      }
      this.useAudioIdx = item.toneType;
      this.playAudio();
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .speak-to-text-warp {
    padding-bottom: 82px;
    padding-top: 30px;
    background: var(--primary-box-hover);
    .title {
      padding: 24px 0;
      text-align: center;
      font-family: Arial, Arial;
      font-weight: bold;
      font-size: 48px;
      color: var(--white);
      line-height: 72px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .textarea {
      border-radius: 30px;
      ::v-deep .el-textarea__inner {
        background: #484848;
        color: #fff;
        border: none;
        border-radius: 30px;
        padding: 30px;
      }
    }
    .speak-box {
      display: flex;
      justify-content: center;
      padding: 36px 36px;
      padding-bottom: 50px;
      max-width: 1400px;
      margin: auto;
      width: 100%;
      background: #333333;
      border-radius: 12px 12px 12px 12px;
      gap: 48px;
      cursor: pointer;
      padding-bottom: 90px;
      .use-button {
        width: 153px;
        height: 40px;
        background: var(--primary-box-color);
        border-radius: 30px;
        font-family: Arial, Arial;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
        line-height: 6px;
        text-align: center;
        font-style: normal;
        text-align: center;
        line-height: 40px;
      }
      .speak-left {
        flex: 1;
        .speak-left-title {
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: 18px;
          color: #fff;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 16px;
        }
        .speak-list {
          max-height: 550px;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }

          .speak-item {
            background: #212121;
            border-radius: 12px 12px 12px 12px;
            .name {
              color: #fff;
            }
            &.active {
              background: #121212;
              .speak-item-top-left {
                .name {
                  color: #fff;
                }
              }
            }
            margin-bottom: 12px;
            padding: 24px;
            .speak-item-top {
              display: flex;
              margin-bottom: 17px;
              color: #333333;
              img {
                width: 56px;
                margin-right: 16px;
              }
              .speak-item-top-left {
                .name {
                  font-family: Arial, Arial;
                  font-weight: bold;
                  font-size: 18px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                  margin-bottom: 6px;
                }
                .tag {
                  background: none;
                  border: none;
                }
              }
            }
            .speak-item-button-list {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 22px;
            }
          }
        }
      }
      .speak-right {
        flex: 0 0 900px;
        .speak-active-box {
          margin-top: 30px;
          display: flex;
          gap: 20px;
          justify-content: flex-end;
          align-items: center;
          .use-button {
            font-weight: 500;
          }
        }
        .input {
          height: 465px;
          margin-bottom: 24px;
          input {
            width: 100%;
            height: 100%;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .speak-to-text-warp {
    position: relative;
    padding-bottom: 2rem;
    background: var(--primary-box-hover);
    padding-top: 2rem;
    .title {
      font-family: Arial, Arial;
      font-weight: bold;
      font-size: 0.4rem;
      color: var(--white);
      text-align: center;
      font-style: normal;
      text-transform: none;
      padding: 0.3rem 0;
      text-align: center;
    }
    .textarea {
      border-radius: 0.6rem;
      ::v-deep .el-textarea__inner {
        background: #484848;
        border: none;
        border-radius: 0.6rem;
        padding: 0.6rem;
        color: #fff;
      }
    }
    .speak-box {
      display: flex;
      flex-direction: column-reverse;
      background: #333333;
      .speak-right {
        padding: 0 0.3rem;
        .speak-active-box {
          position: absolute;
          left: 0;
          bottom: 0;
          background: #333333;
          width: 94.4%;
          padding: 0.2rem;
          display: flex;
          gap: 0.4rem;
          justify-content: center;
          align-items: center;
          .use-button {
            height: 0.8rem;
            background: var(--primary-box-color);
            border-radius: 0.16rem;
            border: 1px solid;
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 0.4rem;
            color: #ffffff;
            text-align: center;
            font-style: normal;
            text-align: center;
            line-height: 0.8rem;
            width: 3.3rem;
          }
        }
      }
      .speak-left {
        padding: 0 0.3rem;
        padding-top: 0.6rem;
        // display: none;
        .speak-left-title {
          text-align: left;
          font-size: 0.3rem;
          font-weight: 400;
          color: #ffffff;
          padding-bottom: 0.3rem;
        }
        .speak-list {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          overflow: auto;
          padding-bottom: 0.2rem;
          align-items: stretch;
          gap: 0.24rem;
          .speak-item {
            padding: 0.24rem 0.14rem;
            width: 2.24rem;
            background: #121212;
            border-radius: 0.24rem;
            background: #212121;
            &.active {
              background: #121212;
              .speak-item-top-left {
                .name {
                  color: #fff;
                }
              }
            }
            .speak-item-top-left {
              height: 100%;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              color: #333333;
              .name {
                margin-bottom: 0.2rem;
                color: #fff;
                min-height: 0.8rem;
              }
              .tag {
                background: none;
                border: none;
              }
            }
            text-align: center;
            border: 1px solid #fff;
            img {
              width: 1.12rem;
            }
            font-weight: 400;
            font-size: 0.24rem;
            color: #333333;
            .speak-item-button-list {
              // display: none;
              :deep(.x-audio-wrap) {
                width: 100%;
                height: 0.5rem;
                border-radius: 0.3rem;
                font-family: Arial, Arial;
                font-weight: bold;
                text-align: center;
                font-style: normal;
                text-align: center;
                line-height: 0.6rem;
                font-size: 0.28rem;
                margin:0.1rem 0;
              }
              .use-button {
                width: 100%;
                height: 0.6rem;
                background: var(--primary-box-color);
                border-radius: 0.3rem;
                font-family: Arial, Arial;
                font-weight: bold;
                font-size: 0.32rem;
                color: #ffffff;
                text-align: center;
                font-style: normal;
                text-align: center;
                line-height: 0.6rem;
                margin-top: 0.1rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="banner-box">
      <div class="banner-left">
        <h1 class="title">{{ i18nContent.title }}</h1>
        <p class="des">{{ i18nContent.des }}</p>
        <div class="mode-warp">
          <el-button
            v-for="(item, index) in modeList"
            :key="index"
            :type="item.id === mode ? 'success' : 'info'"
            @click="mode = item.id"
            size="small"
          >
            {{ item.name }}</el-button
          >
        </div>
        <div class="try-text-warp">
          <div class="try-text-area try-text-area-origin">
            <div class="tryBtn" v-if="!aiText" @click="trySample">
              <div class="icon" />
              Try a Sample
            </div>
            <textarea
              v-model="aiText"
              :placeholder="i18nContent.placeholder"
              name=""
              class="ai-text"
              id="ai-text"
            ></textarea>
            <div class="ai_text_action" v-if="aiText">
              <div class="words">{{ aitextWords }} Words</div>
            </div>
          </div>

          <div
            class="try-text-area try-text-area-generating"
            element-loading-svg-view-box="-10, -10, 50, 50"
            element-loading-background="transparent"
            :class="[{ hide: !generatingText }]"
            v-loading="showLoading"
          >
            <textarea
              v-model="generatingText"
              placeholder="Paraphrased text will appear here"
              name=""
              class="ai-text"
              id="ai-text"
              readOnly
            ></textarea>
            <div class="ai_text_action" v-if="generatingText">
              <div class="words">{{ generatingTextWords }} Words</div>
            </div>
          </div>
        </div>
        <a :href="$origin(i18nContent.link)" @click.prevent="goPath">
          <div
            class="btn"
            :class="[{ 'no-btn': !aiText }]"
            v-loading="showLoading"
            element-loading-background="transparent"
          >
            {{ i18nContent.btnText }}
          </div>
        </a>
      </div>
    </div>
    <div class="banner-right">
      <img :src="$getImages(i18nContent.img)" v-if="i18nContent.img" alt="" />
    </div>
  </div>
</template>
  
<script>
import { conversionhumanizeList, conversionHumanize } from "@/api/pay";
import { mapState } from "vuex";

export default {
  name: "AIText",
  props: {
    content: {
      type: Object,
      default: () => {
        return {
        };
      },
    },
  },
  data() {
    return {
      modeList: [],
      expirationTime: false,
      mode: 1,
      showLoading: false,
      generatingText: "",
      aiText: "",
    };
  },
  computed: {
    i18nContent() {
      let i18nValue = Object.assign({},{
        title: "Anthropomorphic AI Text & Bypass AI Detection",
        des: "",
        
        btnText: "Anthropomorphic AI",
        btnIcon: "textToImage/btn-icon.png",
        placeholder:
          "Paste your AI-generated content here",
        link: "",
        img: "aitext/banner.jpg",
      },this.content);
      if (this.lang === "id") {
        i18nValue = Object.assign({},{
          title: "Antropomorfik AI Teks & Melewati Deteksi AI",
          des: "Teks AI Antropomorfik AI Tercanggih ke Konverter Teks Manusia.",
          btnText: "Antropomorfik AI",
          btnIcon: "textToImage/btn-icon.png",    
          "placeholder": "Paste your AI-generated content here",
          link: "",
          img: "aitext/banner.jpg",
        },this.content);
      }
      return i18nValue;
    },
    lang() {
      return this.$route.params.lang || "en";
    },
    showContent() {
      return this.$replacePlaceholders(this.content);
    },
    ...mapState(["userInfo"]),
    aitextWords() {
      // Split the string by spaces to get an array of words
      return this.aiText.split(" ").length;
    },
    generatingTextWords() {
      // Split the string by spaces to get an array of words
      return this.generatingText.split(" ").length;
    },
  },
  mounted() {
    this.init();
    this.$api.GetUserInfo().then((result) => {
      if (result.code === 200) {
        this.expirationTime = true;
      }
    });
  },
  methods: {
    init() {
      conversionhumanizeList().then((res) => {
        if (res.data.length > 0) {
          this.mode = res.data[0].name;
          this.modeList = res.data.map((res) => {
            return {
              id: res.name,
              name: res.name,
            };
          });
        }
      });
    },
    trySample() {
      this.aiText =
        "AI tools offer a plethora of advantages that cannot be overlooked. By harnessing the power of artificial intelligence, businesses can streamline operations, enhance efficiency, and gain a competitive edge. From automated data analysis to predictive insights, AI tools provide valuable information for informed decision-making. They can automate repetitive tasks, saving time and resources. Additionally, AI tools enable personalized user experiences, increasing customer satisfaction. Embracing AI empowers organizations to unlock untapped potential, drive innovation, and adapt to the evolving landscape of technology.";
    },
    goPath() {
      if (!this.aiText) {
        return;
      }
      const user_email = localStorage.getItem("user_email");
      if (!this.expirationTime ||!user_email) {
        document.querySelector(`#pricing`).scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        return;
      }
      this.showLoading = true;
      conversionHumanize({
        toneType: this.mode,
        text: this.aiText,
      })
        .then((res) => {
          this.generatingText = res.data.text;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>
  
  <style scoped lang="scss">
  @media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      // overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      height: 845px;
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 100px;

      .mode-warp {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        margin: 20px 0;
      }

      .try-text-warp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1200px;
      }

      .try-text-area-origin {
        position: relative;

        &::after {
          position: absolute;
          right: 0px;
          top: 10%;
          height: 80%;
          width: 1px;
          background: #3c3a3a;
          content: "";
        }
      }

      .try-text-area {
        flex: 1;
        max-width: 1200px;
        color: #fff;
        margin: auto;
        width: 100%;
        border-radius: 15px;
        position: relative;

        .tryBtn {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 170px;
          height: 60px;
          border-radius: 12px;
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          margin: auto;
          background: #1c1c1d;
          color: #9fb0a6;
          cursor: pointer;
          font-weight: bold;

          .icon {
            display: inline-block;
            background-color: currentColor;
            mask-image: url("@/assets/images/aitext/aitext.svg");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-color: #9fb0a6;
          }

          &:hover {
            background: #2c2c2e;
            background-color: #2c2c2e;
            color: var(--primary-box-btn);

            .icon {
              background-color: var(--primary-box-btn);
            }
          }
        }

        .ai-text {
          background: #000;
          width: calc(100% - 80px);
          height: 350px;
          color: #fff;
          resize: none;
          border-radius: 15px;
          border: none;
          padding: 40px;
          line-height: 28px;
          font-size: 14px;
          padding-bottom: 80px;
          text-align: center;
          scrollbar-width: none;

          /* Hide the scrollbar in Firefox */
          &::-webkit-scrollbar {
            display: none;
            /* Hide the scrollbar in webkit-based browsers (e.g. Chrome, Safari) */
          }

          border-color: none !important;

          &:focus-visible {
            outline: none !important;
          }
        }

        .ai_text_action {
          // position: absolute;
          // bottom: 30px;
          // left: 0px;
          color: #9fb0a6;
          padding: 0px 30px;
          padding-bottom: 0;
        }
      }

      .banner-box {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        // max-width: 1080px;
        margin: 0 auto;
        padding: 50px 0;

        .banner-left {
          width: 100%;
          height: auto;
          cursor: default;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .title {
            // margin-bottom: 40px;
            font-size: 70px;
            font-weight: 900;
            line-height: 1.1;
            text-align: center;
            max-width: 1200px;
            background: linear-gradient(90deg,
                #c5fbff 0.17%,
                #89fff8 18.52%,
                #4dffa7 54.29%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }

          .des {
            font-size: 24px;
            color: var(--white);
          }

          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--primary-box-btn-color);
            background: var(--primary-box-btn);
            font-size: 20px;
            font-weight: bold;
            margin-top: 40px;
            border-radius: 45px;
            cursor: pointer;

            &.no-btn {
              opacity: 0.5;
            }
          }
        }
      }

      .banner-right {
        width: 40%;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      // overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      min-height: 100vh;
      display: flex;
      align-items: center;
      position: relative;

      .try-text-warp {
        width: 100%;
        max-width: 7.2rem;
        margin: 0 auto;
      }

      .try-text-area-origin {
        position: relative;

        &::after {
          position: absolute;
          right: 0px;
          top: 10%;
          height: 80%;
          width: 1px;
          background: #3c3a3a;
          content: "";
        }
      }

      .try-text-area {
        flex: 1;
        max-width: 7.2rem;
        color: #fff;
        margin: auto;
        width: 100%;
        margin-top: 0.4rem;
        border-radius: 0.15rem;
        position: relative;

        &.hide {
          display: none;
        }

        .tryBtn {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 3.4rem;
          height: 1.2rem;
          border-radius: 0.24rem;
          font-size: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          background: #1c1c1d;
          color: #9fb0a6;
          cursor: pointer;

          .icon {
            display: inline-block;
            background-color: currentColor;
            mask-image: url("@/assets/images/aitext/aitext.svg");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            width: 0.4rem;
            height: 0.4rem;
            margin-right: 0.1rem;
            background-color: #9fb0a6;
          }

          &:hover {
            background: #2c2c2e;
            background-color: #2c2c2e;
            color: var(--primary-box-btn);

            .icon {
              background-color: var(--primary-box-btn);
            }
          }
        }

        .ai-text {
          background: #000;
          width: calc(100% - 0.8rem);
          height: 3.5rem;
          color: #fff;
          border-radius: 0.15rem;
          border: none;
          resize: none;
          padding: 0.4rem;
          line-height: 0.56rem;
          font-size: 0.28rem;
          padding-bottom: 0.8rem;
          scrollbar-width: none;

          /* Hide the scrollbar in Firefox */
          &::-webkit-scrollbar {
            display: none;
            /* Hide the scrollbar in webkit-based browsers (e.g. Chrome, Safari) */
          }

          border-color: none !important;

          &:focus-visible {
            outline: none !important;
          }
        }

        .ai_text_action {
          font-size: 0.28rem;
          // position: absolute;
          // bottom: 30px;
          // left: 0px;
          color: #9fb0a6;
          padding: 0.3rem;
          padding-bottom: 0;
        }
      }

      .banner-box {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin: 0 auto;
        padding: 2rem 0;

        .banner-left {
          width: 90%;
          height: auto;
          cursor: default;
          margin: auto;

          .title {
            margin-bottom: 0.4rem;
            font-size: 0.7rem;
            font-weight: 900;
            background: linear-gradient(90deg,
                #c5fbff 0.17%,
                #89fff8 18.52%,
                #4dffa7 54.29%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }

          .des {
            font-size: 0.48rem;
            color: var(--white);
          }

          .btn {
            width: 4rem;
            padding: 0.3rem 0;
            text-align: center;
            color: var(--primary-box-btn-color);
            background: var(--primary-box-btn);
            font-size: 0.4rem;
            font-weight: bold;
            margin-top: 0.8rem;
            border-radius: 0.9rem;
            cursor: pointer;
            margin: 1rem auto;
          }
        }
      }

      .banner-right {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
</style>
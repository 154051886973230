<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="banner-box">
      <div class="generating-list" v-if="loading">
        <div class="refresh-warp">
          <img :src="$getImages('speaktoimage/refresh.png')" alt="" />
          <div>Refresh</div>
        </div>
        <div
          class="generating-img"
          v-loading="loading"
          element-loading-text="Generating..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <img src="" alt="" />
        </div>
      </div>

      <div class="input-box">
        <input
          class="input"
          v-model="text"
          type="text"
          :placeholder="content.placeholder"
        />
        <div class="btn" @click="generating">
          <img :src="$getImages(content.btnIcon)" v-if="content.btnIcon" alt="" />
          {{ content.btnText }}
        </div>
      </div>
    </div>
    <img class="bg" :src="$getImages(content.img)" v-if="content.img" alt="" />
  </div>
</template>
  
  <script>
export default {
  name: "SpeakToImage",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      text: "",
      loading: false,
    };
  },
  methods: {
    generating() {
      if (this.loading) {
        return;
      }
      if (!this.text) {
        this.$message({
          message: "Please enter text",
          type: "error",
        });
        return;
      }
      this.loading = true;
    },
  },
};
</script>
  
  <style scoped lang="scss">
  @media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      background: #121212;
      width: 100%;
      height: 745px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px;

      .generating-list {
        display: flex;
        height: 604px;
        border-radius: 20px 20px 20px 20px;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;

        .refresh-warp {
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          img {
            width: 36px;
            margin-bottom: 12px;
          }

          margin-right: 48px;
          background: rgba($color: #121212, $alpha: 0.8);

          width: 110px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-family: Arial,
          Arial;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }

        .generating-img {
          flex: 1;
          display: flex;
          align-items: center;

          img {
            height: 100%;
          }
        }
      }

      .banner-box {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 956px;
        margin: auto;

        .title {
          font-family: Arial, Arial;
          font-weight: bold;
          font-size: 70px;
          color: var(--white);
        }

        .des {
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: 36px;
          color: var(--white-70);
          line-height: 46px;
          margin-top: 20px;
        }

        .input-box {
          margin-top: 60px;
          width: 100%;
          height: 90px;
          border-radius: 50px;
          padding: 10px;
          // box-shadow: 0px 25px 50px 0px var(--theme-koutu-price-btn-shadow);
          display: flex;
          border: 1px solid var(--theme-koutu-price-color);
          justify-content: space-between;
          align-items: center;

          .input {
            background: transparent;
            font-size: 20px;
            flex: 1;
            width: 100%;
            height: 100%;
            border: none !important;
            margin: 0 30px;
            color: #fff;
          }

          .btn {
            width: 168px;
            height: 60px;
            flex: 0 0 168px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 20px;
            color: #ffffff;
            line-height: 72px;
            border-radius: 30px;
            cursor: pointer;
            background: var(--theme-koutu-price-btn);
            transition: transform 0.3s ease;

            &:hover {
              transform: scale(1.1);
            }

            img {
              width: 24px;
              margin-right: 10px;
            }
          }
        }
      }

      .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      background: #121212;
      width: 100%;
      min-height: 8rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;

      .banner-box {
        position: relative;
        width: 90%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        margin: auto;

        .title {
          font-family: Arial, Arial;
          font-weight: bold;
          font-size: 0.5rem;
          color: var(--white);
        }

        .des {
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: 0.3rem;
          color: var(--white-70);
          margin-top: 0.2rem;
        }

        .input-box {
          margin-top: 0.6rem;
          width: 100%;
          height: 0.9rem;
          border-radius: 0.5rem;
          padding: 0.1rem;
          // box-shadow: 0px 25px 50px 0px var(--theme-koutu-price-btn-shadow);
          display: flex;
          border: 1px solid var(--theme-koutu-price-color);
          justify-content: space-between;
          align-items: center;

          .input {
            background: transparent;
            font-size: 0.3rem;
            flex: 1;
            width: 100%;
            height: 100%;
            border: none !important;
            margin: 0 0.3rem;
            color: #fff;

            &::placeholder {
              color: #dedede;
            }
          }

          .btn {
            width: 2rem;
            height: 0.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 0.2rem;
            color: #ffffff;
            border-radius: 0.3rem;
            cursor: pointer;
            background: var(--theme-koutu-price-btn);

            img {
              width: 0.24rem;
              margin-right: 0.2rem;
            }
          }
        }
      }

      .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .generating-list {
      width: 100%;

      .refresh-warp {
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        img {
          width: 0.72rem;
          margin-bottom: 0.24rem;
        }

        background: #333333;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Arial,
        Arial;
        font-weight: 400;
        font-size: 0.4rem;
        color: #ffffff;
        text-align: center;
        font-style: normal;
        text-transform: none;
        border-radius: 0.2rem;
      }

      .generating-img {
        min-height: 5rem;
      }

      // .generating-img{
      //   flex: 1;
      //   display: flex;
      //   align-items: center;
      //   img{
      //     height: 100%;
      //   }
      // }
    }
  }
</style>
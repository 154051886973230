<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="banner-box" :class="[content.className]">
      <div class="banner-left">
        <h1 class="title">{{ content.title }}</h1>
        <p class="des">{{ content.des }}</p>
        <a @click.prevent="goPath($origin(content.link))">
          <div class="btn">{{ content.btnText }}</div>
        </a>
      </div>
    </div>
    <div class="banner-right">
      <img :src="$getImages(content.img)" v-if="content.img" alt="" />
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Koutu_Banner_2",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    goPath(link) {
      const user_email = localStorage.getItem("user_email");
      if (!link || !user_email) {
        // this.$message.error('We are trying to optimize the function.')
        document.querySelector(`#pricing`).scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        return;
      }
      window.location.href = link;
    },
  },
};
</script>
  
  <style scoped lang="scss">
  @media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      height: 845px;
      display: flex;
      align-items: center;
      position: relative;

      ::v-deep .white-warp {

        .title,
        .des {
          color: var(--primary-box-black) !important;
        }
      }

      .banner-box {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 0;
        text-align: center;

        .banner-left {
          width: 100%;
          height: auto;
          cursor: default;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .title {
            margin-bottom: 40px;
            font-size: 70px;
            font-weight: 900;
            line-height: 1.1;
            color: var(--white);
          }

          .des {
            font-size: 24px;
            color: var(--white);
          }

          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--primary-box-btn-color);
            background: var(--primary-box-btn);
            font-size: 20px;
            font-weight: bold;
            margin-top: 40px;
            border-radius: 45px;
            cursor: pointer;
          }
        }
      }

      .banner-right {
        width: 40%;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      position: relative;

      ::v-deep .white-warp {

        .title,
        .des {
          color: var(--primary-box-black) !important;
        }
      }

      .banner-box {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin: 0 auto;
        padding: 0.5rem 0;

        .banner-left {
          width: 90%;
          height: auto;
          cursor: default;
          margin: auto;

          .title {
            margin-bottom: 0.4rem;
            font-size: 0.7rem;
            font-weight: 900;
            color: var(--white);
          }

          .des {
            font-size: 0.48rem;
            color: var(--white);
          }

          .btn {
            width: 4rem;
            padding: 0.3rem 0;
            text-align: center;
            color: var(--primary-box-btn-color);
            background: var(--primary-box-btn);
            font-size: 0.4rem;
            font-weight: bold;
            margin-top: 0.8rem;
            border-radius: 0.9rem;
            cursor: pointer;
          }
        }
      }

      .banner-right {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
</style>
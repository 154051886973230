<template>
  <utilsRegister v-if="!show"/>
</template>
<script>
export default {
  components: { },
  name: "register",
  data() {
    return {
      show:true,
    };
  },
  mounted() {
    this.show = true
    this.$nextTick(()=>{
    this.init()
    })
  },
  methods: {
    init(){
    let lang = this.$route.params.lang || "en";
    if (lang === "id") {
      this.$i18n.locale = 'id'
    }
    this.show = false;
    }
  },
};
</script>
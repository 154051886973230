import request from '@/utils/axios'
import { $payApiHost } from "@/utils/index"

// 获取产品信息
export function getProductList(data) {
  return request({
    url: '/product/list',
    method: 'GET',
    params: data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 创建订单
export function createOrder(data) {
  return request({
    url: '/checkout/payment',
    method: 'POST',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 支付
export function payOrder(data) {
  return request({
    url: '/v1/pay',
    method: 'POST',
    data
  })
}

// 检查订单
export function checkOrder(data) {
  return request({
    url: `/transaction/retrieve/${data.id}`,
    method: 'GET',
    data,
    custom: {
      baseURL: $payApiHost(),
    },
  })
}

export function paymentApi(data) {
  return request({
    url: `/pay/in/payment`,
    method: 'post',
    data,
    custom: {
      baseURL: $payApiHost(),
    },
  })
}
//语音转文字
export function conversion(data) {
  return request({
    url: `/text/audio/conversion`,
    method: 'post',
    data,
    custom: {
      baseURL: $payApiHost(),
    },
    responseType: "arraybuffer",
  })
}
//语音转文字列表
export function conversionList(data) {
  return request({
    url: `/text/audio/tone/list`,
    method: 'get',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}
//拟人化转换
export function conversionHumanize(data) {
  return request({
    url: `/text/humanize/conversion`,
    method: 'post',
    data,
    custom: {
      baseURL: $payApiHost(),
    },
  })
}
//拟人化列表
export function conversionhumanizeList(data) {
  return request({
    url: `/text/humanize/tone/list`,
    method: 'get',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}
<template>
  <div class="ai-container" :class="{ aiBackground: content.showBackground }">
    <div class="ai-explain">
      <h2 class="ai-heading">{{ content.title }}</h2>
      <h2 class="ai-subheading">{{ content.subTitle }}</h2>
      <div class="ai-features">
        <template v-for="(item, idx) in content.list">
          <div :key="idx" class="ai-feature">
            <div class="feature-content">
              <h3 class="feature-title" :class="{ highlight: content.highLightTitle }">
                {{ item.title }}
              </h3>
              <p class="feature-description">{{ item.des }}</p>
              <div class="feature-badge">
                <svg v-if="idx % 2 !== 0" xmlns="http://www.w3.org/2000/svg" width="15" height="12" fill="none">
                  <path fill="#4DFFA7" d="M5 8.586 1.707 5.293.293 6.707 5 11.414l9.707-9.707L13.293.293 5 8.586Z">
                  </path>
                </svg>
                {{ item.tag }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Koutu_Explain",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {};
  },
};
</script>
  
<style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
  .ai-container {
    &.aiBackground {
      background: #f7f7f7;
      border-radius: 30px;
    }

    .ai-explain {
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0;
      text-align: center;
      cursor: default;
      font-family: Arial, Arial;

      .ai-heading {
        margin-bottom: 20px;
        font-size: 60px;
        font-weight: 900;
        width: 70%;
        line-height: 1.3;
        margin: 0 auto;
        color: var(--primary-box-black);
      }

      .ai-subheading {
        margin-bottom: 20px;
        font-size: 20px;
        width: 70%;
        line-height: 1.3;
        margin: 0 auto;
        font-weight: 300;
        color: var(--primary-box-black);
      }

      .feature-description {
        font-size: 20px;
        font-weight: 400;
        color: var(--primary-box-tiny);
        line-height: 1.3;
      }

      .ai-features {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 40px;
        margin-top: 60px;
        text-align: left;

        .ai-feature {
          display: flex;
          padding: 25px;
          border-radius: 10px;
          position: relative;

          .feature-badge {
            position: absolute;
            right: 0;
            bottom: 0px;
            padding-bottom: 14px;
            display: inline-block;
            padding: 4px 10px;
            border-radius: 10px 0px 10px 0;
            background: var(--primary-box-hover);
            color: #fff;

            svg {
              margin-right: 4px;
              margin-top: 2px;
            }
          }

          .feature-content {
            padding: 0 40px;

            .feature-title {
              margin-bottom: 20px;
              font-size: 30px;
              font-weight: bold;
              color: #fff;
              line-height: 1.3;

              &.highlight {
                color: #fff;
              }
            }
          }

          &:nth-child(2n) {
            .feature-badge {
              color: #4dffa7;
            }

            background: linear-gradient(180deg, rgba(90, 88, 88, 0.50) 0%, rgba(50, 50, 50, 0.50) 69.79%, rgba(40, 38, 38, 0.50) 100%) !important;

            .feature-title {
              background: linear-gradient(90deg,
                  #c5fbff 0.17%,
                  #89fff8 18.52%,
                  #4dffa7 54.29%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
            }

            background: linear-gradient(90deg,
              #c5fbff 0.17%,
              #89fff8 18.52%,
              #4dffa7 54.29%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;

            .feature-description {
              color: #fff;
            }
          }

          &:nth-child(2n-1) {
            color: #fff;

            .feature-badge {
              color: #FF6C2C;
              background: transparent;
            }

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: var(--primary-box-hover);
              background-attachment: fixed;
              background-size: cover;
              filter: blur(4px);
              z-index: -1;
              border-radius: 10px;
            }

            .feature-description {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .ai-container {
    &.aiBackground {
      background: #f7f7f7;
      border-radius: 30px;
    }

    .ai-explain {
      width: 6.86rem;
      margin: 0 auto;
      padding: 1rem 0;
      text-align: center;
      font-family: Arial, Arial;

      .ai-heading {
        margin-bottom: 0.14rem;
        font-size: 0.48rem;
        color: var(--primary-box-black);
        line-height: 0.72rem;
        font-weight: 900;
      }

      .ai-subheading {
        margin-bottom: 0.14rem;
        font-size: 0.2rem;
        width: 90%;
        line-height: 1.3;
        margin: 0 auto;
        font-weight: 300;
        color: var(--primary-box-black);
      }

      .feature-description {
        font-size: 0.28rem;
        font-weight: 400;
        color: var(--primary-box-black);
        line-height: 0.43rem;
      }

      .ai-features {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.8rem;
        margin-top: 0.4rem;
        text-align: left;

        .ai-feature {
          display: flex;
          padding: 0.25rem;
          border-radius: 0.1rem;
          position: relative;

          .feature-badge {
            position: absolute;
            right: 0;
            bottom: 0px;
            padding-bottom: 0.14rem;
            display: inline-block;
            font-size: 0.2rem;
            padding: 0.04rem 0.1rem;
            border-radius: 0.1rem 0px 0.1rem 0;
            background: var(--primary-box-hover);
            color: #fff;

            svg {
              margin-right: 0.04rem;
              margin-top: 0.02rem;
            }
          }

          .feature-content {
            padding: 0 0.4rem;

            .feature-title {
              margin-bottom: 0.2rem;
              font-size: 0.3rem;
              font-weight: bold;
              color: #fff;
              line-height: 1.3;

              &.highlight {
                color: #fff;
              }
            }
          }

          &:nth-child(2n) {
            .feature-badge {
              color: #4dffa7;
            }

            background: linear-gradient(180deg, rgba(90, 88, 88, 0.50) 0%, rgba(50, 50, 50, 0.50) 69.79%, rgba(40, 38, 38, 0.50) 100%) !important;

            .feature-title {
              background: linear-gradient(90deg,
                  #c5fbff 0.17%,
                  #89fff8 18.52%,
                  #4dffa7 54.29%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
            }

            background: linear-gradient(90deg,
              #c5fbff 0.17%,
              #89fff8 18.52%,
              #4dffa7 54.29%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;

            .feature-description {
              color: #fff;
            }
          }

          &:nth-child(2n-1) {
            color: #fff;

            .feature-badge {
              color: #FF6C2C;
              background: transparent;
            }

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: var(--primary-box-hover);
              background-attachment: fixed;
              background-size: cover;
              filter: blur(4px);
              z-index: -1;
              border-radius: 0.2rem;
            }

            .feature-description {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
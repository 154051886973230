<template>
  <!-- 底部导航栏组件 -->
  <div class="site-footer">
    <div class="footer-wrapper">
      <div class="footer-top-section">
        <div class="footer-content">
          <div class="footer-branding">
            <img :src="$getImages(content.logo)" class="brand-logo" v-if="content.logo" alt="" />
            <div class="brand-logo" v-else>{{ content.title }}</div>
            <div class="brand-description">{{ content.des }}</div>
          </div>
          <p class="mobile-description">{{ content.des }}</p>
          <div class="footer-nav">
            <div v-for="(item, idx) in fastList" :key="idx" v-show="!item.notShow" class="nav-section">
              <div class="section-title">{{ item.title }}</div>
              <p v-for="(target, tIdx) in item.list" :key="tIdx">
                <template v-if="target.type === 'lang'">
                  <el-select placeholder="" v-model="lang" size="small" @change="changeLang">
                    <el-option v-for="item in localsList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </template>
                <template v-else-if="target.type !== undefined">
                  <a :href="$origin(target.name)">{{ target.title }}</a>
                </template>
                <template v-else>
                  <a :rel="target.rel || ''" :href="$origin(target.link)">{{
                    target.title
                    }}</a>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom-section">
        <div class="bottom-content flex-between">
          <div class="company-info">
            <div>{{ content.bottomMobile }}</div>
          </div>
        </div>
      </div>
      <div class="mobile-footer">
        <div>{{ content.bottomMobile }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {
      direction: "down",
      lang: "en",
      localsList: [
        {
          id: "en",
          name: "English",
          language: "en-US",
        },
        {
          id: "id",
          name: "Bahasa Indonesia",
          language: "id-ID",
        },
      ],
    };
  },
  computed: {
    fastList() {
      return this.content.list || [];
    },
  },
  mounted() {
    this.lang = this.$route.params.lang || "en";
  },
  methods: {
    changeLang(lang) {
      if (lang === "en") {
        window.location.href = window.location.origin;
      } else {
        window.location.href = `/${lang}`;
      }
    },
    handleMenu(flag) {
      this.direction = flag ? "up" : "down";
    },
  },
};
</script>

<style lang="scss">
.site-footer {
  .el-select>.el-input {
    background: var(--primary-box-footer-bg);
  }
}

.el-input--small .el-input__inner {
  background: var(--primary-box-footer-bg);
  color: var(--white-70);
  // border: none;
  text-align: center;
  font-size: 14px;
  text-align: left;
}

.brand-logo {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  // text-transform: uppercase;
  color: var(--white);
}

@media #{'only screen and (min-width: 992px)'} {
  .site-footer {
    font-family: Arial, Arial;

    .footer-wrapper {
      .footer-top-section {
        width: auto;
        padding-top: 60px;
        background: var(--primary-box-footer-bg);

        .footer-content {
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 1080px;
          margin: 0 auto;
          padding-bottom: 60px;
          box-sizing: border-box;
          border-bottom: 1px solid #ffffff33;

          .footer-branding {
            // width: 300px;
            border-right: 1px solid #ffffff33;

            img {
              width: 179px;
            }

            .brand-description {
              width: 280px;
              margin-top: 17px;
              margin-right: 60px;
              font-size: 16px;
              font-weight: 400;
              color: var(--white-70);
              line-height: 1.4;
            }
          }

          .footer-nav {
            display: flex;
            gap: 100px;

            .nav-section {
              text-align: center;
              // width: 160px;

              .section-title {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 600;
                color: var(--white);
              }

              p {
                font-size: 16px;
                font-weight: 400;
                word-wrap: break-word;
                line-height: 22px;
                cursor: pointer;

                &:not(:last-child) {
                  margin-bottom: 16px;
                }

                a {
                  color: var(--white-70);
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }

        .mobile-description {
          display: none;
        }
      }

      .footer-bottom-section {
        width: auto;
        padding: 20px 0;
        background: var(--primary-box-footer-bg);
        color: #ffffff33;

        .bottom-content {
          width: 1180px;
          margin: 0 auto;
          text-align: center;

          .company-info {
            position: relative;
            font-size: 14px;
            text-align: center;
            color: var(--white-70);

            img {
              position: absolute;
              right: 0;
              top: -4px;
              width: 300px;
            }
          }
        }
      }

      .mobile-footer {
        display: none;
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .brand-logo {
    text-align: center;
  }

  .site-footer {
    font-family: Arial, Arial;

    .footer-wrapper {
      width: auto;
      background: var(--primary-box-footer-bg);
      color: var(--white);

      .footer-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding-top: 1rem;
        box-sizing: border-box;
        border-bottom: #ffffff33 1px solid;

        .footer-branding {
          img {
            display: none;
          }

          .brand-description {
            display: none;
          }
        }

        .mobile-description {
          min-height: 1.76rem;
          margin-top: 0.4rem;
          font-size: 0.28rem;
          padding: 0.2rem;
          font-weight: 400;
          color: var(--white);
          line-height: 0.44rem;
          text-align: center;
        }

        .footer-nav {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 0.78rem 0.8rem;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0.8rem 0;
          box-sizing: border-box;

          .nav-section {
            width: 2.88rem;

            .section-title {
              margin-bottom: 0.3rem;
              font-size: 0.32rem;
              color: var(--white);
              line-height: 0.53rem;
            }

            p {
              font-size: 0.3rem;
              font-weight: 400;
              line-height: 0.46rem;
              cursor: pointer;
              word-wrap: break-word;

              &:not(:last-child) {
                margin-bottom: 0.32rem;
              }

              a {
                color: var(--white-70);
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .footer-bottom-section {
        display: none;
      }

      .mobile-footer {
        margin: 0 0.32rem;
        padding: 0.41rem 0 0.3rem;
        font-size: 0.24rem;
        color: var(--white);
        align-items: center;
        line-height: 0.35rem;
        text-align: center;

        img {
          width: 3rem;
        }
      }
    }
  }
}
</style>

<template>
  <!-- banner包裹组件 -->
  <div class="banner-container">
    <div class="swiper-box">
      <swiper ref="swiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div class="banner" :style="{ background: bg }">
            <slot :data="item"></slot>
          </div>
        </swiper-slide>
      </swiper>
      <div v-if="list.length > 1" class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwiBanner',
  props: {
    bg: {
      type: String,
      required: false,
      default: () => '#FFFFFF'
    },
    list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      activeIdx: 0,
      swiperOptions: {
        // 无限
        loop: true,
        //自动轮播图
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
      }
    }
  },
  methods: {
    // swiperSwitch(idx) {
    //   this.$refs.swiper.swiper.slideTo(idx + 1)
    // },

  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner-container {
    overflow: hidden;

    .swiper-box {
      position: relative;
      height: auto;
      overflow: hidden;

      .banner {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 12px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        z-index: 10;


        .pagination-dot {
          width: 8px;
          height: 8px;
          cursor: pointer;
          border-radius: 5px;
          background: #fff;
          transition: width 0.2s ease;

          &.active {
            width: 18px;
            height: 8px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .banner-container {
    background: #f4f2ff;
    overflow: hidden;

    .swiper-box {
      position: relative;
      height: auto;
      overflow: hidden;
      background: #fff;

      .banner {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-box) !important;
        margin: 1.54rem 0.32rem 0;
        border-radius: 0.24rem;
      }

      .pagination-container {
        gap: 12px;
        position: absolute;
        bottom: 0.3rem;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .pagination-dot {
          width: 0.16rem;
          height: 0.16rem;
          cursor: pointer;
          border-radius: 5px;
          background: #fff;
          transition: width 0.2s ease;

          &.active {
            width: 0.36rem;
            height: 0.16rem;
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>

<style>
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #FFFFFF;
  opacity: .6;
}

.swiper-pagination-bullet-active {
  width: 18px;
  height: 8px;
  border-radius: 8px;
  opacity: .8;
  background-color: #FFFFFF;
}
</style>
<template>
  <div class="utils-register-warp">
    <el-form
      :model="form"
      class="form"
      :rules="rules"
      ref="registerForm"
      label-position="top"
    >
      <el-form-item :label="$t('register.EmailAddress')" prop="email">
        <el-input
          v-model="form.email"
          :placeholder="$t('register.EmailAddressplace')"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('register.passwordtitle')" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          :label="$t('register.passwordplaceholder')"
        ></el-input>
      </el-form-item>
      <el-form-item 
      :label="$t('register.confirmPasswordtitle')"
       prop="confirmPassword">
        <el-input
          type="password"
          v-model="form.confirmPassword"
          :placeholder="$t('register.passwordplaceholder1')"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loadStart"
          type="primary"
          class="btn register-btn"
          @click="register"
          >{{ $t("register.register") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "utilsRegister",
  data() {
    return {
      confirmPassword: "",
      countdown: 60,
      countingDown: false,
      loadStart: false,
      form: {
        email: "",
        code: "",
        confirmPassword: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t("register.email"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("register.email1"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("register.password"),
            trigger: "blur",
          },
          {
            min: 6,
            message: this.$t("register.password1"),
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("register.code"),
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: this.$t("register.confirmPassword"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error(this.$t("register.confirmPassword1")));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["OPEN_LOGIN"]),
    sendVerificationCode() {
      this.$refs.registerForm.validateField("email", (error) => {
        if (!error) {
          this.countingDown = true;
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              this.countingDown = false;
              this.countdown = 60;
              clearInterval(timer);
            }
          }, 1000);
          this.$message.success(this.$t("register.sendEmail"));
        }
      });
    },
    register() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loadStart = true;
          this.$api
            .Register({
              identifier: this.form.email,
              credential: this.form.password,
            })
            .then((res) => {
              this.loadStart = false;
              if (res.code === 200) {
                this.OPEN_LOGIN(true);
              } else {
                this.$message.error(res.msg);
              }
              this.OPEN_LOGIN(true);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.utils-register-warp {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  gap: 0;
  padding-bottom: 100px;
  .form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 32px;
    .el-input__suffix {
      right: 0;
    }
  }
  .btn {
    background: var(--primary-box-btn);
    color: #fff;
    border: none;
  }
  .register-btn {
    width: 100%;
  }
}
@media (max-width: 980px) {
  .utils-register-warp {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .el-form-item {
      margin-bottom: 0;
      .register-btn {
        margin-top: 1rem;
      }
    }
  }
}
</style>
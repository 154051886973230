<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(()=>{
    this.init()
    })
  },
  methods: {
    init(){
    let lang = this.$route.params.lang || "en";
    if (lang === "id") {
      this.$i18n.locale = 'id'
    }
    }
  },
};
</script>
<style lang="scss">
</style>

<template>
  <!-- FAQ组件 -->
  <div class="faq-container" :style="content.style || {}">
    <div class="faq-wrapper">
      <component :is="content.tag || 'h2'" class="faq-title">{{ content.title }}</component>
      <div class="faq-list">
        <div v-for="(item, idx) in content.list" :key="idx" class="faq-item">
          <div :class="['faq-item__header', activeIdx === idx && 'is-active']" @click="checkedActive(idx)">
            <div class="faq-item__title">
              <img class="faq-item__icon" :src="$getImages(content.listIcon)" v-if="content.listIcon" alt="">
              {{ item.title }}
            </div>
            <div class="faq-item__toggle">
              <img class="faq-item__toggle-icon--desktop"
                :style="{ transform: activeIdx === idx ? 'rotate(-0)' : 'rotate(180deg)' }"
                :src="$getImages('questions/question-close-icon.svg')" alt="">
              <img class="faq-item__toggle-icon--mobile"
                :style="{ transform: activeIdx === idx ? 'rotate(0)' : 'rotate(180deg)' }"
                :src="$getImages('questions/question-close-icon.svg')" alt="">
            </div>
          </div>
          <template v-for="(des, dIdx) in item.desList">
            <div ref="default" :key="'d' + dIdx" :class="['faq-item__content']">
              <div ref="question" :class="['faq-item__description', activeIdx === idx && 'is-active']">
                <span v-html="des.des" />
                <ul class="faq-item__list">
                  <li v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des" class="faq-item__list-item">
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Koutu_Questions',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      activeIdx: '',
      showMobileHeader: false
    }
  },
  onLoad() {
  },
  methods: {
    checkedActive(idx) {
      this.activeIdx = this.activeIdx === idx ? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
      this.$refs.default.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeIdx === idx) {
        const activeEl = this.$refs.default[idx]
        activeEl.style.height = this.$refs.question[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
  .faq-container {
    font-family: Arial, Arial;

    .faq-wrapper {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      padding: 60px 0;

      .faq-title {
        margin: 0 auto 40px;
        font-size: 60px;
        font-weight: 900;
        color: var(--primary-box-black);
        width: 80%;
        line-height: 1.3;
        text-align: center;
      }

      .faq-list {
        background-color: var(--white);
        border-radius: 12px;

        .faq-item {
          &:not(:last-child) {
            border-bottom: 1px solid #EDEDED;
          }

          .faq-item__header {
            display: flex;
            justify-content: space-between;
            padding: 32px 0;
            line-height: 52px;
            transition: all 200ms;
            cursor: pointer;

            &:hover {
              .faq-item__title {
                color: var(--primary-box-color);
              }
            }

            .faq-item__title {
              font-size: 20px;
              font-weight: bold;
              color: var(--primary-box-black);
              display: flex;
              align-items: center;
              gap: 10px;

              .faq-item__icon {
                width: 24px;
                height: 24px;
              }
            }

            .faq-item__toggle {
              display: flex;
              align-items: center;
              justify-content: center;
              width: auto;
              height: auto;
              text-align: center;
              cursor: pointer;

              .faq-item__toggle-icon--desktop {
                width: 35px;
                height: 35px;
                transition: all 200ms;
              }

              .faq-item__toggle-icon--mobile {
                display: none;
                width: 16px;
                height: 16px;
                transition: all 200ms;
              }
            }
          }

          .is-active {
            padding-bottom: 12px;
          }

          .faq-item__content {
            height: 0;
            overflow: hidden;
            transition: all 0.25s ease-out;

            .faq-item__description {
              display: block;
              opacity: 0;
              font-size: 18px;
              font-weight: 400;
              line-height: 1.5;
              color: var(--primary-box-tiny);
              box-sizing: border-box;
              transition: all 0.25s ease-out;

              &.is-active {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .faq-container {
    font-family: Arial, Arial;
    -webkit-tap-highlight-color: transparent;

    .faq-wrapper {
      padding: 1.2rem 0.31rem;

      .faq-title {
        margin-bottom: .5rem;
        font-size: .48rem;
        color: var(--theme-font-question-title);
        line-height: .72rem;
        text-align: center;
        font-weight: 900;
      }

      .faq-list {
        background-color: var(--white);
        padding: 0 .1rem;

        .faq-item {
          &:not(:last-child) {
            border-bottom: 1px solid #EDEDED;
          }

          .faq-item__header {
            display: flex;
            justify-content: space-between;
            padding: .46rem 0 0;
            line-height: .64rem;
            cursor: pointer;
            transition: all 200ms;

            .faq-item__title {
              font-size: .32rem;
              font-weight: 500;
              color: var(--primary-box-black);
              line-height: .48rem;
              display: flex;
              align-items: flex-start;
              gap: 10px;

              .faq-item__icon {
                width: 0.48rem;
                height: 0.48rem;
              }
            }

            .faq-item__toggle {
              display: flex;
              align-items: center;
              flex: none;
              width: .5rem;
              height: .5rem;
              margin-left: .32rem;
              padding-top: .1rem;

              .faq-item__toggle-icon--desktop {
                display: none;
              }

              .faq-item__toggle-icon--mobile {
                width: 100%;
                height: 100%;
                transition: all 200ms;
              }
            }
          }

          .faq-item__content {
            height: 0;
            margin-top: .48rem;
            overflow: hidden;
            transition: all .25s ease-out;

            .faq-item__description {
              display: block;
              opacity: 0;
              font-size: .28rem;
              line-height: .42rem;
              color: var(--theme-font-question-tiny);
              box-sizing: border-box;
              transition: all .25s ease-out;

              &.is-active {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>

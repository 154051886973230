<template>
    <div class="koutu_comment">
        <div class="koutu_content">
            <p class="title">{{ content.title }}</p>
            <div class="area">
                <div v-for="(item, index) in content.list" :key="index" class="item">
                    <p class="subtitle">{{ item.subtitle }}</p>
                    <div class="wrap">
                        <img :src="$getImages(item.img)" v-if="item.img" alt="">
                        <div>
                            <div class="name">{{ item.name }}</div>
                            <div class="city">{{ item.city }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_Comment',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
 @media #{'only screen and (min-width: 992px)'} {
    .koutu_comment{
        width: 100%;
        background-color: #F6F6F6;
        .koutu_content {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 1080px;
            margin: 0 auto;
            padding: 80px 0;
            font-family: Arial, Arial;

            .title {
                margin-bottom: 12px;
                font-size: 60px;
                font-weight: 900;
                color: var(--primary-box-black);
                    line-height: 1.3;
                    text-align: center;
                    // width: 70%;
                    margin: 0 auto 30px;
                }
                
                .area {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px;
                    width: 100%;
                    margin-top: 42px;
                
                    .item {
                        position: relative;
                        padding: 30px 20px;
                        box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
                        border-radius: 36px;
                        background-color: white;
                        overflow: hidden;
                        transition: all .3s;
                        overflow: visible;
                
                        img {
                            display: block;
                            margin-right: 20px;
                        }
                
                        .wrap {
                            display: flex;
                            align-items: center;
                        }
                
                        &:hover {
                            background: var(--primary-box-btn);
                
                            .subtitle,
                            .name,
                            .city {
                                color: white;
                            }
                        }
                
                        .subtitle {
                            margin-bottom: 40px;
                            font-size: 20px;
                            color: var(--primary-box-black);
                            line-height: 1.4;
                            min-height: 100px;
                        }
                
                        .name {
                            font-size: 20px;
                            color: #4C484C;
                            margin-bottom: 8px;
                        }
                
                        .city {
                            font-size: 16px;
                            color: #999199;
                        }
                    }
                }
                }
                }
                }
                
                @media #{'only screen and (max-width: 991px)'} {
                    .koutu_comment {
                        width: 100%;
                        background-color: #F6F6F6;
                
                        .koutu_content {
                            width: 100%;
                            padding: .8rem 0;
                            font-family: Arial, Arial;
                
                            .title {
                                margin: 0 auto .5rem;
                                font-size: .48rem;
                                color: var(--primary-box-black);
                                text-align: center;
                                font-weight: 900;
                            }
                
                            .area {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                gap: .5rem;
                
                                .item {
                                    position: relative;
                                    padding: .5rem .4rem;
                                    border-radius: .2rem;
                                    //   transition: all .3s;
                                    background-color: white;
                                    overflow: visible;
                                    margin: 0 .3rem;
                
                                    img {
                                        display: block;
                                        margin-right: .3rem;
                                    }
                
                                    .wrap {
                                        display: flex;
                                        align-items: center;
                                    }
                
                                    .subtitle {
                                        margin-bottom: .3rem;
                                        line-height: 1.4;
                                        font-size: .3rem;
                                        color: var(--primary-box-black);
                                    }
                
                                    .name {
                                        margin-bottom: .1rem;
                                        font-size: .4rem;
                                        color: #4C484C;
                                        // line-height: 1.2;
                                    }
                
                                    .city {
                                        font-size: .3rem;
                                        color: #999199;
                                    }
                                }
                            }
                        }
                    }
                
                }
</style>
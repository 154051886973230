<template>
  <!-- FAQ组件 -->
  <div class="faq" :style="content.style || {}">
    <div class="faq__container">
      <component :is="content.tag || 'h2'" class="faq__title">{{ content.title }}</component>
      <div class="faq__list">
        <div v-for="(item, idx) in content.list" :key="idx" class="faq__item">
          <div :class="['faq__item-header', activeIdx === idx && 'is-active']" @click="checkedActive(idx)">
            <div class="faq__item-title" :style="{ color: activeIdx === idx ? 'var(--theme-focus)' : '' }">
              {{ item.title }}
            </div>
            <div class="faq__item-toggle">
              <img class="faq__item-toggle-icon--desktop"
                :style="{ transform: activeIdx === idx ? 'rotate(-0)' : 'rotate(-135deg)' }"
                :src="$getImages('questions/question-close-icon.svg')" alt="">
              <img class="faq__item-toggle-icon--mobile"
                :style="{ transform: activeIdx === idx ? 'rotate(0)' : 'rotate(-135deg)' }"
                :src="$getImages('questions/question-close-icon.svg')" alt="">
            </div>
          </div>
          <template v-for="(des, dIdx) in item.desList">
            <div ref="default" :key="'d' + dIdx" :class="['faq__item-content']">
              <div ref="question" :class="['faq__item-description', activeIdx === idx && 'is-active']">
                <span v-html="des.des" />
                <ul class="faq__item-list">
                  <li v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des" class="faq__item-list-item">
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Questions',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      activeIdx: '',
      showMobileHeader: false
    }
  },
  onLoad() {
  },
  methods: {
    checkedActive(idx) {
      this.activeIdx = this.activeIdx === idx ? '' : idx
      this.$refs.default.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeIdx === idx) {
        const activeEl = this.$refs.default[idx]
        activeEl.style.height = this.$refs.question[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .faq {
    &__container {
      width: 90%;
      max-width: 1080px;
      margin: 0 auto;
      padding: 140px 0 108px 0;
    }

    &__title {
      margin: 0 0 48px;
      font-size: 36px;
      font-family: Poppins;
      color: var(--theme-primary-black);
      line-height: 52px;
      text-align: center;
    }

    &__list {
      .faq__item {
        &:not(:last-child) {
          border-bottom: 1px solid #EDEDED;
        }

        &-header {
          display: flex;
          justify-content: space-between;
          padding: 32px 0;
          line-height: 52px;
          transition: all 200ms;
          cursor: pointer;

          &:hover {
            .faq__item-title {
              color: var(--theme-primary-suffix);
            }
          }
        }

        &-title {
          font-size: 20px;
          font-weight: 500;
          font-family: Poppins-Medium, Poppins;
          color: var(--theme-primary-black);
          line-height: 32px;
        }

        &-toggle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
          text-align: center;
          cursor: pointer;

          &-icon {
            &--desktop {
              width: 16px;
              height: 16px;
              transition: all 200ms;
            }

            &--mobile {
              display: none;
              width: 16px;
              height: 16px;
              transition: all 200ms;
            }
          }
        }

        &-content {
          height: 0;
          overflow: hidden;
          transition: all 0.25s ease-out;
        }

        &-description {
          display: block;
          opacity: 0;
          font-size: 16px;
          font-weight: 400;
          font-family: Poppins-Regular, Poppins;
          color: var(--theme-font-question-tiny);
          line-height: 24px;
          box-sizing: border-box;
          transition: all 0.25s ease-out;

          &.is-active {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .faq {
    -webkit-tap-highlight-color: transparent;

    &__container {
      padding: 1.2rem 0.31rem;
    }

    &__title {
      margin-bottom: .12rem;
      font-size: .48rem;
      font-family: Poppins;
      color: var(--theme-font-question-title);
      line-height: .72rem;
      text-align: center;
    }

    &__list {
      .faq__item {
        &:not(:last-child) {
          border-bottom: 1px solid #EDEDED;
        }

        &-header {
          display: flex;
          justify-content: space-between;
          padding: .46rem 0 0;
          line-height: .64rem;
          cursor: pointer;
          transition: all 200ms;

          &.is-active {
            color: var(--theme-primary-suffix);
          }
        }

        &-title {
          font-size: .32rem;
          font-weight: 500;
          font-family: Poppins-Medium, Poppins;
          color: var(--theme-primary-black);
          line-height: .48rem;
        }

        &-toggle {
          display: flex;
          align-items: center;
          flex: none;
          width: .32rem;
          height: .32rem;
          margin-left: .32rem;
          padding-top: .1rem;

          &-icon {
            &--desktop {
              display: none;
            }

            &--mobile {
              width: 100%;
              height: 100%;
              transition: all 200ms;
            }
          }
        }

        &-content {
          height: 0;
          margin-top: .48rem;
          overflow: hidden;
          transition: all .25s ease-out;
        }

        &-description {
          display: block;
          opacity: 0;
          font-size: .28rem;
          line-height: .42rem;
          font-family: Poppins-Regular, Poppins;
          color: var(--theme-font-question-tiny);
          box-sizing: border-box;
          transition: all .25s ease-out;

          &.is-active {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}
</style>

<template>
  <!-- 首页轮播图组件 -->
  <div class="reading-mode-container">
    <h2>{{ content.title }}</h2>
    <div>
      <div class="reading-content">
        <div class="reading-left">
          <div v-for="(item, index) in content.list" v-show="activeIdx == index" :key="index">
            <img class="reading-img" :src="$getImages('reading-' + (index + 1) + '.webp')" alt="">
            <img :class="'reading-img' + (index + 1)" :src="$getImages('anima-' + (index + 1) + '.webp')" alt="">
          </div>
        </div>
        <div class="reading-right">
          <div class="reading-right-content">
            <div :class="['reading-content-item', activeIdx == index ? '' : 'active']"
              v-for="(item, index) in content.list" :key="index" @click="hoverHandle($event, index)">
              <div class="row">
                <img class="reading-img" :src="$getImages(item.icon)" alt="">
                <div class="reading-title">{{ item.title }}</div>
              </div>
              <div v-show="activeIdx == index" class="reading-desc">{{ item.desc }}</div>
            </div>
          </div>
          <!-- H5 -->
          <div class="reading-swiper-box">
            <swiper ref="swiper" :options="swiperOptions">
              <swiper-slide v-for="(item, index) in content.list" :key="index">
                <div class="reading-content-item">
                  <div class="row-center">
                    <div class="reading-img">
                      <img :src="$getImages(item.leftImg)" alt="">
                      <img :class="'reading-img' + index" :src="$getImages(item.leftImgH5)" alt="">
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="reading-swiper-pagation">
              <div v-for="(item, index) in content.list" :key="index"
                :class="['reading-swiper-pagation-dot', activeIdx == index ? '' : 'active']" @click="swiperSwitch(index)">
                <div class="row">
                  <img class="reading-img" :src="$getImages(item.icon)" alt="">
                  <div class="reading-title">{{ item.title }}</div>
                </div>
                <div v-show="swiperIdx == index" class="reading-desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils'
export default {
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    let that = this
    return {
      activeIdx: 0,
      swiperIdx: 0,
      swiperOptions: {
        initialSlide: 0,
        autoplay: true,
        allowTouchMove: false,
        disableOnInteraction: true,
        loop: true,
        on: {
          transitionStart() {
            that.swiperIdx = this.realIndex
          }
        }
      },
      stopHandle: true
    }
  },
  mounted() {

    this.index = 0
    if (!utils.isMobile()) {
      this.timer = setInterval(() => {
        if (!this.stopHandle) {
          return
        }
        if (this.index > 3) {
          this.index = 0
        }
        this.hoverHandle(null, this.index)
        this.index++
      }, 3500)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    hoverHandle(e, index) {
      this.activeIdx = index
      this.index = index
      if (e) {
        let event = e.type
        switch (event) {
          case 'mouseenter':
            this.stopHandle = false
            break
          case 'mouseleave':
            this.stopHandle = true
            break
        }
      }
    },
    swiperSwitch(idx) {
      this.$refs.swiper.swiper.slideTo(idx + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 981px) {
  .reading-mode-container {
    padding: 80px 0 80px 0;
    width: 100%;
    max-width: 1580px;
    margin: 0 auto;
    position: relative;

    h2 {
      width: 1000px;
      font-size: 36px;
      font-family: Poppins;
      color: var(--primary-box-black);
      line-height: 52px;
      text-align: center;
      margin: 0 auto;
    }

    .reading-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      .reading-left {
        margin-right: 68px;
        position: relative;

        .reading-img {
          width: 560px;
          height: 380px;
        }

        .reading-img1 {
          position: absolute;
          width: 483px;
          height: 239px;
          left: 20px;
          top: 20px;
          animation: move 2s ease-in infinite;
        }

        .reading-img2 {
          position: absolute;
          width: 500px;
          height: 303px;
          left: 20px;
          top: 30px;
          animation: move 2s ease-in infinite;
        }

        .reading-img3 {
          position: absolute;
          width: 449px;
          height: 277px;
          left: 40px;
          top: 110px;
          animation: move 2s ease-in infinite;
        }

        .reading-img4 {
          position: absolute;
          width: 479px;
          left: 22px;
          top: 26px;
          animation: move 2s ease-in infinite;
        }
      }

      .reading-right {
        .reading-right-content {
          .reading-content-item {
            width: 90%;
            max-width: 514px;
            padding: 22px 20px;
            border-radius: 8px;
            background: var(--primary-box);
            transition: height 0.2s ease;
            cursor: pointer;

            &:not(:last-of-type) {
              margin-bottom: 12px;
            }

            &.active {
              background: var(--theme-suffix-box);
            }

            .row {
              display: flex;
              align-items: center;
            }

            .reading-img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }

            .reading-title {
              color: #05021e;
              font-size: 20px;
              line-height: 24px;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
            }

            .reading-desc {
              margin-left: 30px;
              margin-top: 8px;
              color: #36354e;
              font-family: Poppins-Regular, Poppins;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .reading-swiper-box {
          display: none;
        }

        .start-btn {
          color: white;
          font-size: 18px;
          line-height: 30px;
          font-family: Poppins-Medium, Poppins;
          background-color: var(--primary-box);
          width: 200px;
          height: 56px;
          margin-top: 24px;
          cursor: pointer;

          &:hover {
            background-color: var(--theme-btn-hover);
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .reading-mode-container {
    padding: 1.2rem .32rem .87rem;
    position: relative;
    width: 6.86rem;

    h2 {
      font-size: .48rem;
      font-family: Poppins;
      color: var(--primary-box-black);
      line-height: 0.72rem;
      text-align: center;
      margin: 0 auto;
    }

    .reading-content {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: .48rem;

      .reading-left {
        display: none;
        position: relative;

        .reading-img {
          width: 5.97rem;
          height: 4.8rem;
        }
      }

      .reading-right {
        width: 100%;

        .reading-right-content {
          display: none;

          .reading-content-item {
            padding: 22px 20px;
            border-radius: 8px;
            background-color: #f4f2ff;

            &:not(:first-of-type) {
              background-color: #faf9ff;
            }

            &:not(:last-of-type) {
              margin-bottom: 12px;
            }

            .reading-img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }

            .reading-title {
              color: #05021e;
              font-size: 20px;
              line-height: 24px;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
            }

            .reading-desc {
              margin-left: 30px;
              margin-top: 8px;
              color: #36354e;
              font-family: Poppins-Regular, Poppins;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .reading-swiper-box {
          width: 100%;
          position: relative;

          .reading-content-item {
            width: 100%;
            overflow: hidden;

            .reading-img {
              width: 100%;

              img {
                width: 100%;
                height: 100%;
              }

              .reading-img0 {
                position: absolute;
                width: 5.1rem;
                height: 2.5rem;
                left: 0.7rem;
                top: 0.2rem;
                animation: move 2s ease-in infinite;
              }

              .reading-img1 {
                position: absolute;
                width: 5.35rem;
                height: 3.2rem;
                left: 0.65rem;
                top: 0.4rem;
                animation: move 2s ease-in infinite;
              }

              .reading-img2 {
                position: absolute;
                width: 4.8rem;
                height: 3rem;
                left: 0.88rem;
                top: 1.1rem;
                animation: move 2s ease-in infinite;
              }

              .reading-img3 {
                position: absolute;
                width: 5.1rem;
                height: 3.45rem;
                left: 0.7rem;
                top: 0.35rem;
                animation: move 2s ease-in infinite;
              }
            }

            .reading-title {
              color: var(--primary-box);
              font-size: 0.32rem;
              line-height: 0.45rem;
              margin-top: 0.4rem;
              font-weight: 500;
              font-family: Poppins-Medium, Poppins;
              text-align: center;
            }

            .reading-desc {
              color: #36354e;
              font-size: 0.28rem;
              line-height: 0.45rem;
              margin-top: 0.24rem;
              font-family: Poppins-Regular, Poppins;
              text-align: center;
            }
          }

          .reading-swiper-pagation {
            display: flex;
            flex-direction: column;
            align-items: center;

            .reading-swiper-pagation-dot {
              padding: 0 0.2rem;
              background: var(--primary-box);
              border-radius: 0.1rem;
              transition: width 0.2s ease;
              margin-bottom: 0.2rem;

              &.active {
                width: 6.46rem;
                height: auto;
                background: var(--theme-suffix-box);
              }

              .row {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 6.46rem;
                border-radius: 0.16rem;
                padding: 0.25rem 0;

                .reading-img {
                  width: 0.4rem;
                  margin-right: 0.12rem;
                }

                .reading-title {
                  font-size: 0.32rem;
                  font-family: Poppins-Medium, Poppins;
                  color: #000029;
                  line-height: 0.45rem;
                  margin-left: 0.05rem;
                }
              }

              .reading-desc {
                font-size: 0.28rem;
                font-family: Poppins-Regular, Poppins;
                color: #36354e;
                line-height: 0.4rem;
                padding-bottom: 0.2rem;
              }
            }
          }
        }

        .start-btn {
          display: none;
          color: white;
          font-size: 18px;
          line-height: 30px;
          font-family: Poppins-Medium, Poppins;
          background-color: var(--primary-box);
          width: 200px;
          height: 56px;
          margin-top: 24px;
          cursor: pointer;

          &:hover {
            background-color: var(--theme-btn-hover);
          }
        }
      }
    }
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>